@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);

.loader_loader__3R8xD {
    color: #ffffff;
    font-size: 90px;
    text-indent: -9999em;
    overflow: hidden;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    margin: 72px auto;
    position: relative;
    transform: translateZ(0);
    -webkit-animation: loader_load6__3WMz0 1.7s infinite ease, loader_round__2PL6R 1.7s infinite ease;
    animation: loader_load6__3WMz0 1.7s infinite ease, loader_round__2PL6R 1.7s infinite ease;
  }
  @-webkit-keyframes loader_load6__3WMz0 {
    0% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    5%,
    95% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    10%,
    59% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
    }
    20% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
    }
    38% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
    }
    100% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
  }
  @keyframes loader_load6__3WMz0 {
    0% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    5%,
    95% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    10%,
    59% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
    }
    20% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
    }
    38% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
    }
    100% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
  }
  @-webkit-keyframes loader_round__2PL6R {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes loader_round__2PL6R {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
html,
body,
#root {
  height: 100%;
}

body {
  font-family: 'Inter', sans-serif;
  margin: 0;
  background: #161616;
}

.mainBackground {
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  -webkit-animation: Gradient 15s ease infinite;
  animation: Gradient 15s ease infinite;
}

@-webkit-keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.catBackground {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #161616;
}

.whiteBackground {
  background: white;
}

.appWrapper_menuBar__g3WG6 {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  padding: 1px 16px 1px 16px;
  background: #323232;
}

.appWrapper_iconButton__326nK {
  height: 46px;
  width: 46px;
}

.appWrapper_iconButtonIcon__3zzD4 {
  font-size: 24px !important;
}

.Login_loginBox__1j4JC {
  padding: 80px 0;
  text-align: center;
  height: 100%;
  background: wheat;
  position: fixed;
  width: 100%;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 40%,
    rgba(255, 255, 255, 0.8) 60%,
    rgba(255, 255, 255, 0.1) 100%
  );
}

.Login_loginBox__1j4JC h1 {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 40px;
  transition-duration: 1s;
  transition-timing-function: ease-in-put;
  font-weight: 300;
  margin-top: 0;
}

.Login_loginInputWrapper__2psZc {
  display: block;
  margin-top: 13px;
}

.Login_loginInput__1uiAa {
  width: 250px;
}

.cat_spaceer__2lXx4 {
  height: 100px;
}

.cat_cat__3PEb6 {
  position: relative;
  height: 170px;
  width: 192.1px;
}

.cat_ear__354nW {
  position: absolute;
  top: -30%;
  height: 60%;
  width: 25%;
  background: #fff;
}
.cat_ear__354nW::before,
.cat_ear__354nW::after {
  content: '';
  position: absolute;
  bottom: 24%;
  height: 10%;
  width: 5%;
  border-radius: 50%;
  background: #161616;
}
.cat_ear__354nW::after {
  transform-origin: 50% 100%;
}

.cat_ear--left__3B_z0 {
  left: -7%;
  border-radius: 70% 30% 0% 0% / 100% 100% 0% 0%;
  transform: rotate(-15deg);
}
.cat_ear--left__3B_z0::before,
.cat_ear--left__3B_z0::after {
  right: 10%;
}
.cat_ear--left__3B_z0::after {
  transform: rotate(-45deg);
}

.cat_ear--right__3n15S {
  right: -7%;
  border-radius: 30% 70% 0% 0% / 100% 100% 0% 0%;
  transform: rotate(15deg);
}
.cat_ear--right__3n15S::before,
.cat_ear--right__3n15S::after {
  left: 10%;
}
.cat_ear--right__3n15S::after {
  transform: rotate(45deg);
}

.cat_face__251-0 {
  position: absolute;
  height: 100%;
  width: 100%;
  background: #161616;
  border-radius: 50%;
}

.cat_eye__2VRhy {
  position: absolute;
  top: 35%;
  height: 30%;
  width: 31%;
  background: #fff;
  border-radius: 50% 50% 50% 50% / 60% 60% 40% 40%;
}
.cat_eye__2VRhy::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 100%;
  border-radius: 0 0 50% 50% / 0 0 40% 40%;
  background: #161616;
  -webkit-animation: cat_blink__1SPKg 4s infinite ease-in;
  animation: cat_blink__1SPKg 4s infinite ease-in;
}
@-webkit-keyframes cat_blink__1SPKg {
  0% {
    height: 0;
  }
  90% {
    height: 0;
  }
  92.5% {
    height: 100%;
  }
  95% {
    height: 0;
  }
  97.5% {
    height: 100%;
  }
  100% {
    height: 0;
  }
}
@keyframes cat_blink__1SPKg {
  0% {
    height: 0;
  }
  90% {
    height: 0;
  }
  92.5% {
    height: 100%;
  }
  95% {
    height: 0;
  }
  97.5% {
    height: 100%;
  }
  100% {
    height: 0;
  }
}
.cat_eye__2VRhy::before {
  content: '';
  position: absolute;
  top: 60%;
  height: 10%;
  width: 15%;
  background: #fff;
  border-radius: 50%;
}

.cat_eye--left__18CSj {
  left: 0;
}
.cat_eye--left__18CSj::before {
  right: -5%;
}

.cat_eye--right__1Wvco {
  right: 0;
}
.cat_eye--right__1Wvco::before {
  left: -5%;
}

.cat_eye-pupil__oE4_S {
  position: absolute;
  top: 25%;
  height: 50%;
  width: 20%;
  background: #161616;
  border-radius: 50%;
  -webkit-animation: cat_look-around__17pWc 4s infinite;
  animation: cat_look-around__17pWc 4s infinite;
}
@-webkit-keyframes cat_look-around__17pWc {
  0% {
    transform: translate(0);
  }
  5% {
    transform: translate(50%, -25%);
  }
  10% {
    transform: translate(50%, -25%);
  }
  15% {
    transform: translate(-100%, -25%);
  }
  20% {
    transform: translate(-100%, -25%);
  }
  25% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}
@keyframes cat_look-around__17pWc {
  0% {
    transform: translate(0);
  }
  5% {
    transform: translate(50%, -25%);
  }
  10% {
    transform: translate(50%, -25%);
  }
  15% {
    transform: translate(-100%, -25%);
  }
  20% {
    transform: translate(-100%, -25%);
  }
  25% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}
.cat_eye--left__18CSj .cat_eye-pupil__oE4_S {
  right: 30%;
}
.cat_eye--right__1Wvco .cat_eye-pupil__oE4_S {
  left: 30%;
}
.cat_eye-pupil__oE4_S::after {
  content: '';
  position: absolute;
  top: 30%;
  right: -5%;
  height: 20%;
  width: 35%;
  border-radius: 50%;
  background: #fff;
}

.cat_muzzle__3qeXv {
  position: absolute;
  top: 60%;
  left: 50%;
  height: 6%;
  width: 10%;
  background: #fff;
  transform: translateX(-50%);
  border-radius: 50% 50% 50% 50% / 30% 30% 70% 70%;
}

